import React from 'react'
import Header from '../Header/Header'
import './ViewMaster.css'
import Footer from '../Footer/Footer'

function ViewMaster(Component) {
    const NewComponent = (props) => {
        return <>
            <div className="gameHeader" style={{ padding: "1rem" }}>
                <Header name={props.name} />
            </div>
            <div>
                {/* style={{ padding: "1rem" }} */}
                <Component {...props} />
            </div>
            <Footer />
        </>
    }

    return NewComponent
}

export default ViewMaster